<section class="work-area pt-100">
    <br><br>
    <div class="container">
        <div class="section-title">
            <h2>Presenta tu Queja</h2>
        </div>
        <div class="row justify-content-center">
            <div class="contact-form">
                <form (ngSubmit)="quejaForm(formQueja.value)" #formQueja="ngForm">
                    <div class="row">
                        <div class="col-lg-4 col-md-4">
                            <div class="form-group mb-3">
                                <label for="nombre">Nombre completo</label>
                                <input type="text" class="form-control" name="nombre" ngModel required>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2">
                            <div class="form-group mb-3">
                                <label>Género</label>
                                <input type="text" class="form-control" name="genero" ngModel>
                            </div>
                        </div>
                        <div class="col-lg-1 col-md-1">
                            <div class="form-group mb-3">
                                <label>Edad</label>
                                <input type="number" class="form-control" name="edad" ngModel required>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2">
                            <div class="form-group mb-3">
                                <label>Escolaridad</label>
                                <input type="text" class="form-control" name="escolaridad" ngModel>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3">
                            <div class="form-group mb-3">
                                <label>Ocupación</label>
                                <input type="text" class="form-control" name="ocupacion" ngModel>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4">
                            <div class="form-group mb-3">
                                <label>Domicilio</label>
                                <input type="text" class="form-control" name="domicilio" ngModel required>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-3">
                            <div class="form-group mb-3">
                                <label>Colonia</label>
                                <input type="text" class="form-control" name="colonia" ngModel required>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2">
                            <div class="form-group mb-3">
                                <label>Municipio</label>
                                <input type="text" class="form-control" name="municipio" ngModel required>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2">
                            <div class="form-group mb-3">
                                <label>Estado</label>
                                <input type="text" class="form-control" name="estado" ngModel required>
                            </div>
                        </div>
                        <div class="col-lg-1 col-md-1">
                            <div class="form-group mb-3">
                                <label>C.P.</label>
                                <input type="text" class="form-control" name="cp" ngModel>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4">
                            <div class="form-group mb-3">
                                <label>Teléfono</label>
                                <input type="text" class="form-control" name="telefono" ngModel required>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4">
                            <div class="form-group mb-3">
                                <label>Nacionalidad</label>
                                <input type="text" class="form-control" name="nacionalidad" ngModel>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4">
                            <div class="form-group mb-3">
                                <label for="email">Correo electrónico</label>
                                <input type="email" class="form-control" name="email" ngModel>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <label for="mensaje">Descripción de hechos</label>
                                <textarea class="form-control" cols="30" rows="4" name="descripcion" ngModel
                                    required="required"></textarea>
                            </div>
                        </div>

                        <div class="row align-items-center">
                            <div class="col-4 text-center">
                                <button type="submit" class="btn btn-primary w-100">Enviar mensaje</button>
                            </div>
                            <div class="col-8">
                                <p class="mb-0">
                                    Los datos personales serán utilizados para dar debido trámite a su solicitud y para
                                    fines estadísticos.
                                    Puede consultar nuestro aviso de privacidad integral en la siguiente liga:
                                    <a href="https://www.codhey.org/#/avisos-privacidad" target="_blank"
                                        rel="noopener noreferrer">Aviso de privacidad integral</a>.
                                </p>
                            </div>
                        </div>


                    </div>
                </form>
            </div>

        </div>
    </div>
</section>