<app-encabezado-modulo [smallTitle]="smallTitle" [title]="title" [description]="description" [imgPortada]="imgPortada">
</app-encabezado-modulo>
<br><br>
<section class="work-area">
    <div class="container">
        <div class="row">
            <div class="section-title">
                <p>
                    Horario de atención: Lunes a viernes de 08:00 a 20:00 horas.
                    <br>En otro horario favor de dejar algún
                    correo o dato de contacto para su seguimiento.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <iframe width="100%" height="350px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                    allowtransparency="true"
                    src="https://chatroll.com/embed/chat/4pmx?id=6ClKnoFfFk1&platform=html"></iframe>
            </div>
        </div>
        <p>
            Los datos personales serán utilizados para dar debido trámite a su solicitud y para fines estadísticos.
            Puede consultar nuestro aviso de privacidad integral en la siguiente liga:
            <a href="https://www.codhey.org/#/avisos-privacidad" target="_blank" rel="noopener noreferrer">Aviso de
                privacidad integral</a>.
        </p>

    </div>
</section>